import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import WarningIcon from "@mui/icons-material/Warning";

import { RenderParams } from ".";
import { bookingStatusColors } from "../../../helpers/booking-status-colors";
import { bookingStatusTranslations } from "../../../helpers/booking-status-translations";
import paymentMethodTranslation from "../../../helpers/payment-method-translation";
import ActionButtons from "./ActionButtons";
import { Booking } from "../../../types";
import { getActivity } from "../View";

export function renderViewBooking(params: RenderParams) {
  return (
    <>
      <Tooltip title="予約詳細を見る">
        <IconButton size="small" href={`/bookings/${params.row.id}`}>
          <VisibilityIcon style={{ color: "#c8a063" }} fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <ActionButtons row={params.row} />
    </>
  );
}

export function renderCancellationNotes(params: RenderParams) {
  return (
    <div>
      {params.row.booking.cancellationFee
        ? params.row.booking.cancellationFee + " JPY"
        : "なし"}
    </div>
  );
}

export function renderStatus(params: RenderParams) {
  return (
    <span
      style={{
        color:
          bookingStatusColors[
            params.row.booking.noShow
              ? "NOSHOW"
              : (params.row.booking.status as Exclude<
                  Booking["status"],
                  "CREATED"
                >)
          ],
      }}
    >
      {
        bookingStatusTranslations[
          params.row.booking.noShow
            ? "NOSHOW"
            : (params.row.booking.status as Exclude<
                Booking["status"],
                "CREATED"
              >)
        ]
      }
    </span>
  );
}

export function renderCreatedAt(params: RenderParams) {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      {params.row.booking.createdAt.substr(0, 10)}
      <br />
      {params.row.booking.createdAt.substr(10, 9)}
    </div>
  );
}

export function renderVenue(params: RenderParams) {
  return (
    <Tooltip title={params.row.activity.venue.name}>
      <div
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.row.activity.venue.name}
      </div>
    </Tooltip>
  );
}

export function renderPlan(params: RenderParams) {
  return (
    <Tooltip
      title={
        params?.row?.booking?.planSnapshot?.name ??
        params.row.activity.plan.name
      }
    >
      <div
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <a
          style={{ color: "#000" }}
          target="_blank"
          rel="noreferrer"
          href={`https://oiwai.anny.gift/celebration-plans/${params.row.activity.plan.id}`}
        >
          {params?.row?.booking?.planSnapshot?.name ??
            params.row.activity.plan.name}
        </a>
      </div>
    </Tooltip>
  );
}

export function renderCourse(params: RenderParams) {
  const activity = getActivity(
    params.row.activityId,
    params.row.booking.planSnapshot?.activities
  );

  return (
    <Tooltip title={activity?.name ?? params?.row?.activity?.json?.json?.name}>
      <div
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {activity?.name ?? params?.row?.activity?.json?.json?.name}
      </div>
    </Tooltip>
  );
}

export function renderSeat(params: RenderParams) {
  return (
    <Tooltip title={params.row.seatOption.title}>
      <div
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.row.seatOption.title}
      </div>
    </Tooltip>
  );
}

export function renderCustomer(params: RenderParams) {
  return (
    <div>
      {params.row.booking.familyName} {params.row.booking.givenName} 様<br />
      {params.row.booking.familyNameFurigana}{" "}
      {params.row.booking.givenNameFurigana}
    </div>
  );
}

export function renderDatetime(params: RenderParams) {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      {params.row.datetime.substr(0, 10)}
      <br />
      {params.row.datetime.substr(10, 6)}
    </div>
  );
}

export function renderItems(params: RenderParams) {
  const items = params.row.items.map((i, index) => (
    <span key={index}>
      {i.priceType.name}: {i.quantity}
      <br />
    </span>
  ));

  return (
    <Tooltip title={items}>
      <div>{items}</div>
    </Tooltip>
  );
}

export function renderPayment(params: RenderParams) {
  return (
    <div>
      {params.row.booking.paymentAmount} {params.row.booking.paymentCurrency}
      <br />
      {paymentMethodTranslation[params.row.booking.payment.method]}
    </div>
  );
}

export function renderSpecialRequests(
  params: RenderParams,
  setModal: (value: null | string) => void
) {
  if (!params.row.specialRequests) return <></>;

  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <Tooltip title="ご要望を見る">
        <IconButton
          size="small"
          onClick={() => setModal(params.row.specialRequests)}
        >
          <WarningIcon style={{ color: "#c8a063" }} />
        </IconButton>
      </Tooltip>
    </div>
  );
}

const renders: {
  [key: string]: (
    params: RenderParams,
    ...props: any
  ) => JSX.Element | undefined;
} = {
  actions: renderViewBooking,
  status: renderStatus,
  createdAt: renderCreatedAt,
  venue: renderVenue,
  plan: renderPlan,
  course: renderCourse,
  seat: renderSeat,
  customer: renderCustomer,
  datetime: renderDatetime,
  items: renderItems,
  payment: renderPayment,
  cancellationNotes: renderCancellationNotes,
  specialRequests: renderSpecialRequests,
};

export default renders;
