import { useContext, useMemo } from "react";
import { FormContext } from "../../components/FormContext";
import ImageUploaderCard from "../../components/ImageUploaderCard";
import { RevertableArrayItem } from "../../components/RevertableForPlan";
import { getArrayFieldValue, getArrayItemState } from "../../formValues";
import { FieldState } from "../../types";
import { ActivitiesField } from "../types";
import { generatePhotoUrl } from "./CoursePhotoList";
import { useDrag, useDrop } from "react-dnd";
import { useRef } from "react";
import { Card } from "@mui/material";

export default function CoursePhotoCard(props: CoursePhotoCardProps) {
  const {
    index,
    itemIndex,
    formIdentifier,
    setImageUrl,
    planId,
    photoId,
    remove,
    setDeleted,
  } = props;
  const { form } = useContext(FormContext);
  const arrayName = `${ActivitiesField.Course}.${index}.${ActivitiesField.CoursePhotos}`;
  const { watch } = form;

  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop<any>({
    accept: "Card",
    hover(item, _monitor) {
      if (item.photoId === props.photoId) return;
    },
    drop(item, monitor) {
      if (!monitor.didDrop()) {
        props.moveFunction(
          watch(`${item.formIdentifier}.${ActivitiesField.CoursePhotoSrc}`),
          watch(`${props.formIdentifier}.${ActivitiesField.CoursePhotoSrc}`)
        );
      }
    },
  });

  const [, drag] = useDrag({
    type: "Card",
    item: props,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const isDeleted =
    getArrayFieldValue({
      form,
      arrayName,
      itemIndex,
      fieldName: ActivitiesField.CoursePhotoIsDeleted,
      photoIndex: index,
    }) ?? false;

  const isAdded =
    getArrayItemState({ form, arrayName, itemIndex, photoIndex: index }) ===
    FieldState.added;

  const imageProps = useMemo(() => {
    return isDeleted
      ? {
          style: {
            opacity: "30%",
          },
        }
      : {};
  }, [isDeleted]);

  return (
    <Card className="EditorFaqCardContainer" sx={{ height: "100%" }} ref={ref}>
      <RevertableArrayItem
        arrayName={arrayName}
        itemIndex={itemIndex}
        photoIndex={index}
        isDeleted={isDeleted}
        remove={remove}
      >
        <div style={{ margin: "0.25rem 0.75rem" }}>
          <ImageUploaderCard
            mediaUrl={watch(
              `${formIdentifier}.${ActivitiesField.CoursePhotoSrc}`
            )}
            setMediaUrl={setImageUrl}
            mutable={!isAdded}
            path={generatePhotoUrl(planId, photoId)}
            imageProps={imageProps}
            isDeleted={isDeleted}
            handleDelete={() => setDeleted(true)}
            undoDelete={() => setDeleted(false)}
          />
        </div>
      </RevertableArrayItem>
    </Card>
  );
}

type CoursePhotoCardProps = {
  planId: string;
  photoId: string;
  itemIndex: number;
  index?: number;
  setImageUrl: (imageUrl: string | null) => void;
  formIdentifier: string;
  setDeleted: (isDeleted: boolean) => void;
  remove: () => void;
  moveFunction: (sourceImg: string, targetImg: string) => void;
};
