import { useEffect, useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { Button, IconButton, Tooltip } from "@mui/material";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Reservation } from "..";
import { useNotifications } from "../../../../components/Notification";

interface ShowBookingProps {
  reservation: Reservation;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ShowBooking({
  reservation,
  setLoading,
}: ShowBookingProps) {
  const { showNotification } = useNotifications();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showBooking, { loading, data }] = useMutation(SHOW_BOOKING);

  useEffect(() => setLoading(loading), [loading, setLoading, data]);

  return (
    <>
      <Tooltip title="ノーショー">
        <IconButton size="small" onClick={() => setDialogOpen(true)}>
          <RemoveCircleRoundedIcon
            style={{ color: "green" }}
            fontSize="inherit"
          />
        </IconButton>
      </Tooltip>
      <ConfirmDialog />
    </>
  );

  function ConfirmDialog() {
    return (
      <Dialog open={dialogOpen}>
        <DialogContent>この予約をノーショー設定しますか？</DialogContent>
        <DialogActions>
          <Button
            style={{ color: "#000" }}
            onClick={() => setDialogOpen(false)}
          >
            いいえ
          </Button>
          <Button
            style={{ color: "green" }}
            onClick={() => handleShowBooking()}
          >
            はい
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  function handleShowBooking() {
    showBooking({
      variables: {
        reservationId: reservation.id,
        noShow: !reservation.booking.noShow,
      },
      refetchQueries: ["GetBookingsListData"],
    })
      .then((data: any) => {
        if (data?.data?.confirmReservation?.success === false) {
          setLoading(false);
          return showNotification({
            message:
              data?.data?.confirmReservation?.message ??
              "ショーの予約変更に失敗しました!",
            severity: "error",
          });
        }
        showNotification({
          message: "ショーの予約変更が完了しました！",
          severity: "success",
        });
      })
      .catch(() =>
        showNotification({
          message: "ショーの予約変更に失敗しました!",
          severity: "error",
        })
      );

    setDialogOpen(false);
  }
}

const SHOW_BOOKING = gql`
  mutation ShowBooking($reservationId: ID!, $noShow: Boolean!) {
    showBooking(input: { reservationId: $reservationId, noShow: $noShow }) {
      success
      message
    }
  }
`;
