import { Link, TableCell, TableRow } from "@mui/material";
import styles from "../../../styles";
import { Box } from "@mui/system";
import { isProductionEnvironment } from "../../../../../../helpers/environment";
import {
  allTimeSlotsAreClosed,
  getClosedTimeSlots,
  getStatus,
  hasTimeSlots,
  isClosed,
  isClosedDueToHoliday,
  isClosedHoliday,
  isCutoff,
  isLocked,
  UseSchedules,
} from "../../useSchedules";
import OpenCloseDay from "./components/atoms/OpenCloseDay";
import Status from "./components/atoms/Status";
import { HolidaysModalButton } from "./components/atoms/HolidaysModal";
import NumBooking from "./components/atoms/NumBooking";
import { Holiday } from "../../helpers";
import { useCallback } from "react";
import { PlanSchedule } from "../../useSchedules/useFetchSchedule";
import React from "react";
import useRateBooking from "../../../../../../helpers/use-rate-booking";
import Divider from "@mui/material/Divider";

export type TimeSlot = {
  moment: string;
  open: boolean;
};

type PlanProps = Pick<
  UseSchedules,
  | "addTimeSlot"
  | "toggleDate"
  | "toggleTimeSlot"
  | "toggleAllTimeSlots"
  | "toggleHoliday"
  | "setSchedules"
> & {
  schedule: PlanSchedule;
  plan: UseSchedules["plans"][number];
  numBookings: UseSchedules["numBookings"][string];
  numApproval: UseSchedules["numApproval"][string];
  numApprovalPrevMonth: UseSchedules["numApprovalPrevMonth"][string];
  numTotalBookings: UseSchedules["numTotalBookings"][string];
  numTotalBookingsPrevMonth: UseSchedules["numTotalBookingsPrevMonth"][string];
  dates: string[];
};

const Plan = ({
  plan,
  schedule,
  toggleDate,
  toggleAllTimeSlots,
  toggleHoliday,
  toggleTimeSlot,
  addTimeSlot,
  ...props
}: PlanProps) => {
  const planId = plan.planId;
  const planBookingType = plan.bookingType;
  const storefrontUrl = isProductionEnvironment
    ? "https://oiwai.anny.gift"
    : "https://staging-oiwai.anny.gift";

  const toggleHolidayForSchedule = useCallback(
    (holiday: Holiday) => toggleHoliday(schedule, holiday),
    [toggleHoliday, schedule]
  );

  const isClosedHolidayForSchedule = useCallback(
    (holiday: Holiday) => isClosedHoliday(schedule, holiday),
    [schedule]
  );

  return (
    <>
      <TableRow sx={styles.planDivider}>
        <TableCell
          sx={[styles.cell, styles.planNameCell, styles.planDivider]}
          rowSpan={3}
        >
          <Box style={{ display: "flex", gap: "1rem" }}>
            <Box>
              <Link
                sx={styles.linkToPlan}
                target="_blank"
                rel="noreferrer"
                href={`${storefrontUrl}/celebration-plans/${planId}`}
                underline="none"
              >
                <span style={styles.viewPlanText}>プランページを見る</span>
                <br />
                {plan.planName}
              </Link>
              <Box sx={styles.planSpecifics}>
                <ul style={styles.unstyledList}>
                  {Array.from(
                    new Map(
                      plan.priceTypes.map((item) => [item.id, item])
                    ).values()
                  ).map((pt) => (
                    <li key={pt.id}>
                      {pt.name}: {pt.amount}{" "}
                      {pt.currencyCode === "JPY" ? "円" : pt.currencyCode}
                    </li>
                  ))}
                </ul>
              </Box>
              <Box sx={styles.planSpecifics}>
                <ul style={styles.unstyledList}>
                  {Array.from(
                    new Map(
                      plan.seatOptions.map((item) => [item.id, item])
                    ).values()
                  ).map((so) => (
                    <li key={so.id}>{so.title}</li>
                  ))}
                </ul>
              </Box>
            </Box>
            <HolidaysModalButton
              toggleHoliday={toggleHolidayForSchedule}
              isClosedHoliday={isClosedHolidayForSchedule}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              gap: "1rem",
              borderTop: "solid 1px #d2d2d2",
              paddingTop: "5px",
            }}
          >
            <Box style={{ flex: 1 }}>
              <Box>今月の予約承認率:</Box>
              <Box>
                {useRateBooking(props.numApproval, props.numTotalBookings)}%
              </Box>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box style={{ flex: 1 }}>
              <Box>先月の予約承認率:</Box>
              <Box>
                {useRateBooking(
                  props.numApprovalPrevMonth,
                  props.numTotalBookingsPrevMonth
                )}
                %
              </Box>
            </Box>
          </Box>
        </TableCell>

        <TableCell sx={[styles.cell, styles.planDivider]}>予約数</TableCell>
        {props.dates.map((date, index) => (
          <NumBooking
            planId={planId}
            date={date}
            numBookings={props.numBookings[date]}
            disabled={isLocked(schedule, date)}
            key={index}
          />
        ))}
      </TableRow>
      {planBookingType === "AVAILABILITIES" || planBookingType === "INSTANT" ? (
        <>
          <TableRow>
            <TableCell
              align="center"
              sx={styles.uneditableAvailabilityNotifications}
            >
              エビカと在庫情報連携中
            </TableCell>
          </TableRow>
          <TableRow>
            <></>
          </TableRow>
        </>
      ) : (
        <>
          <TableRow>
            <TableCell sx={styles.cell}>売り止め日</TableCell>
            {props.dates.map((date) => (
              <OpenCloseDay
                key={date}
                toggleDate={toggleDate}
                hasTimeSlots={hasTimeSlots(schedule, date)}
                date={date}
                isCutoff={isCutoff(schedule, date)}
                isLocked={isLocked(schedule, date)}
                isChecked={isClosed(schedule, date)}
                status={schedule.dates[date].state}
                isClosedDueToHoliday={isClosedDueToHoliday(schedule, date)}
                planId={planId}
              />
            ))}
          </TableRow>
          <TableRow>
            <TableCell sx={[styles.cell, styles.statusText]}>
              予約受入状況
            </TableCell>
            {props.dates.map((date) => (
              <Status
                key={date}
                planId={planId}
                date={date}
                schedule={schedule}
                status={getStatus(schedule, date)}
                hasTimeSlots={hasTimeSlots(schedule, date)}
                isClosed={isClosed(schedule, date)}
                isClosedDueToHoliday={isClosedDueToHoliday(schedule, date)}
                isLocked={isLocked(schedule, date)}
                closedTimeSlots={getClosedTimeSlots(schedule, date)}
                allTimeSlotsAreClosed={allTimeSlotsAreClosed(schedule, date)}
                isCutoff={isCutoff}
                toggleAllTimeSlots={toggleAllTimeSlots}
                toggleTimeSlot={toggleTimeSlot}
                addTimeSlot={addTimeSlot}
              />
            ))}
          </TableRow>
        </>
      )}
    </>
  );
};

export default React.memo(Plan);
