import { Reservation } from "..";
import ConfirmBooking from "./ConfirmReservation";
import RejectReservation from "./RejectReservation";
import CancelReservation from "./CancelReservation";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import ShowBooking from "./ShowBooking";

interface ActionButtonsProps {
  row: Reservation;
}

export default function ActionButtons(props: ActionButtonsProps) {
  const [loading, setLoading] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      {loading && <CircularProgress size={16} />}
      <div style={{ display: loading ? "none" : "block" }}>
        {props.row.booking.status === "REQUESTED" && (
          <>
            <ConfirmBooking reservation={props.row} setLoading={setLoading} />
            <RejectReservation
              reservation={props.row}
              setLoading={setLoading}
            />
          </>
        )}
        {props.row.booking.status === "CONFIRMED" && (
          <>
            <CancelReservation
              reservation={props.row}
              setLoading={setLoading}
            />
            <ShowBooking reservation={props.row} setLoading={setLoading} />
          </>
        )}
      </div>
    </div>
  );
}
