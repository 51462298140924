import { Activity } from "./pages/Bookings/View";
import { Holiday } from "./pages/Plans/Availability/VenueAvailability/helpers";

export type LockableMoment = {
  moment: string;
  locked: boolean;
};

export const daysOfTheWeek = [
  "SU",
  "MO",
  "TU",
  "WE",
  "TH",
  "FR",
  "SA",
] as const;

export type PlanSchedule = {
  cutoffTimeHours: number;
  bookablePeriodMonths: number;
  closedDates: LockableMoment[];
  closedTimeSlots: string[];
  closedHolidays: string[];
  openDates: string[];
  openTimeSlots: string[];
  days: {
    holiday?: Holiday;
    dayOfTheWeek?: typeof daysOfTheWeek[number];
    timeSlots: string[];
  }[];
};

export type PlanAvailability = {
  planId: string;
  planName: string;
  planOrder: number | null;
  bookingType: BookingType;
  priceTypes: {
    id: string;
    name: string;
    amount: number;
    currencyCode: string;
  }[];
  seatOptions: {
    id: string;
    title: string;
  }[];
  schedule: PlanSchedule;
  availability: {
    date: string;
    status: AvailabilityStatus;
    timeSlots: string[];
    numBookings: number;
    numApproval: number;
    numTotalBookings: number;
  }[];
  availabilityPrevMonth: {
    date: string;
    numBookings: number;
    numTotalBookings: number;
  }[];
};

export type AvailabilityStatus =
  | "AVAILABLE"
  | "NOT_AVAILABLE"
  | "PARTIALLY_AVAILABLE";

export type BookingType = "INSTANT" | "AVAILABILITIES" | "ON-REQUEST";

export type Schedule = {
  cutoffTimeHours: string[];
  bookablePeriodMonths: string[];
  closedDates: {
    moment: string;
    locked: boolean;
  }[];
  closedTimeSlots: string[];
  closedHolidays: string[];
  openDates: string[];
  openTimeSlots: string[];
  days: {
    dayOfTheWeek: string[];
    timeSlots: string[];
  }[];
};
export type Plan = {
  id: string;
  name: string;
  schedule: Schedule;
  activities?: Activity[];
};

export type Payment = {
  method: "STRIPE" | "AMAZON_PAY" | "ON_SITE_PAYMENT";
};

export type Booking = {
  id: string;
  familyName: string;
  givenName: string;
  familyNameFurigana: string;
  givenNameFurigana: string;
  email: string;
  phoneNumber: string;
  paymentAmount: number;
  paymentCurrency: string;
  createdAt: string;
  payment: Payment;
  noShow: boolean;
  status: "CREATED" | "CONFIRMED" | "REQUESTED" | "REJECTED" | "CANCELLED";
  cancellationReason?: string;
  cancellationFee?: number;
  planSnapshot?: {
    name: string;
    cancelFee?: number;
    activities: [ActivityJson];
  };
};

export type ActivityJson = {
  id: string;
  name: string;
  priceTypes: [PriceTypeJson];
};

export type PriceTypeJson = {
  id: string;
  name: string;
};
